import React from "react";
import { DefaultLayout } from "~/Ship/Layouts/DefaultLayout";

const CarTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/CarTypePage"));
const CarBrandPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/CarBrandPage"));
const CarModelPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/CarModelPage"));
const FuelTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/FuelTypePage"));
const CarUsageReasonPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/CarUsageReasonPage"));
const CarUsageTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/CarUsageTypePage"));
const DesignRestrictionPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/DesignRestrictionPage"));
const DocumentTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/DocumentTypePage"));
const ExchangeRatePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/ExchangeRatePage"));
const FaqPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/FaqPage"));
const GenderPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/GenderPage"));
const InsuranceProductBenefitPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/InsuranceProductBenefitPage"));
const InsuranceProductPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/InsuranceProductPage"));
const PartnerPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/PartnerPage"));
const PartnerInsuranceProductDiscountPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/PartnerInsuranceProductDiscountPage"));
const AgentPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/AgentPage"));
const AgentJobTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/AgentJobTypePage"));
const MessageEventPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/MessageEventPage"));
const MessageMileageBalancePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/MessageMileageBalancePage"));
const MessageTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/MessageTypePage"));
const PaymentTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/PaymentTypePage"));
const RiskPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/RiskPage"));
const SourcePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/SourcePage"));
const StatusPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/StatusPage"));
const TariffInsuranceProductPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/TariffInsuranceProductPage"));
const TariffKilometerPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/TariffKilometerPage"));
const TrackingSystemPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/TrackingSystemPage"));
const VerificationTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/VerificationTypePage"));
const TaskTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/TaskTypePage"));
const BonusTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/BonusTypePage"));
const BonusTransactionTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/BonusTransactionTypePage"));
const BonusRatingMonthPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/BonusRatingMonthPage"));
const MobileAppVersionPage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/MobileAppVersionPage"));
const CallTypePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/CallTypePage"));
const CallStatePage = React.lazy(() => import("~/Containers/SettingSection/ListContainer/Pages/CallStatePage"));

const EmployeesPage = React.lazy(() => import("~/Containers/SettingSection/EmployeeContainer/Pages/EmployeesPageComponent"));

import SetSectionMenuTask from "../Tasks/SetSectionMenuTask";

export const SettingRoute = [
    {
        path: '/setting',
        element: <DefaultLayout />,
        loader: () => {
            SetSectionMenuTask.run();
            return null;
        },
        children: [
            { index: true, element: <React.Suspense fallback={<>...</>}><EmployeesPage /></React.Suspense> },
            { path: 'list', children: [
                { path: 'car-type', element: <React.Suspense fallback={<>...</>}><CarTypePage /></React.Suspense> },
                { path: 'car-brand', element: <React.Suspense fallback={<>...</>}><CarBrandPage /></React.Suspense> },
                { path: 'car-model', element: <React.Suspense fallback={<>...</>}><CarModelPage /></React.Suspense> },
                { path: 'fuel-type', element: <React.Suspense fallback={<>...</>}><FuelTypePage /></React.Suspense> },
                { path: 'car-usage-reason', element: <React.Suspense fallback={<>...</>}><CarUsageReasonPage /></React.Suspense> },
                { path: 'car-usage-type', element: <React.Suspense fallback={<>...</>}><CarUsageTypePage /></React.Suspense> },
                { path: 'design-restriction', element: <React.Suspense fallback={<>...</>}><DesignRestrictionPage /></React.Suspense> },
                { path: 'document-type', element: <React.Suspense fallback={<>...</>}><DocumentTypePage /></React.Suspense> },
                { path: 'exchange-rate', element: <React.Suspense fallback={<>...</>}><ExchangeRatePage /></React.Suspense> },
                { path: 'faq', element: <React.Suspense fallback={<>...</>}><FaqPage /></React.Suspense> },
                { path: 'gender', element: <React.Suspense fallback={<>...</>}><GenderPage /></React.Suspense> },
                { path: 'insurance-product-benefit', element: <React.Suspense fallback={<>...</>}><InsuranceProductBenefitPage /></React.Suspense> },
                { path: 'insurance-product', element: <React.Suspense fallback={<>...</>}><InsuranceProductPage /></React.Suspense> },
                { path: 'partner', element: <React.Suspense fallback={<>...</>}><PartnerPage /></React.Suspense> },
                { path: 'partner-insurance-product-discount', element: <React.Suspense fallback={<>...</>}><PartnerInsuranceProductDiscountPage /></React.Suspense> },
                { path: 'agent', element: <React.Suspense fallback={<>...</>}><AgentPage /></React.Suspense> },
                { path: 'agent-job-type', element: <React.Suspense fallback={<>...</>}><AgentJobTypePage /></React.Suspense> },
                { path: 'message-event', element: <React.Suspense fallback={<>...</>}><MessageEventPage /></React.Suspense> },
                { path: 'message-mileage-balance', element: <React.Suspense fallback={<>...</>}><MessageMileageBalancePage /></React.Suspense> },
                { path: 'message-type', element: <React.Suspense fallback={<>...</>}><MessageTypePage /></React.Suspense> },
                { path: 'payment-type', element: <React.Suspense fallback={<>...</>}><PaymentTypePage /></React.Suspense> },
                { path: 'risk', element: <React.Suspense fallback={<>...</>}><RiskPage /></React.Suspense> },
                { path: 'source', element: <React.Suspense fallback={<>...</>}><SourcePage /></React.Suspense> },
                { path: 'status', element: <React.Suspense fallback={<>...</>}><StatusPage /></React.Suspense> },
                { path: 'tariff-insurance-product', element: <React.Suspense fallback={<>...</>}><TariffInsuranceProductPage /></React.Suspense> },
                { path: 'tariff-kilometer', element: <React.Suspense fallback={<>...</>}><TariffKilometerPage /></React.Suspense> },
                { path: 'tracking-system', element: <React.Suspense fallback={<>...</>}><TrackingSystemPage /></React.Suspense> },
                { path: 'verification-type', element: <React.Suspense fallback={<>...</>}><VerificationTypePage /></React.Suspense> },
                { path: 'task-type', element: <React.Suspense fallback={<>...</>}><TaskTypePage /></React.Suspense> },
                { path: 'bonus-type', element: <React.Suspense fallback={<>...</>}><BonusTypePage /></React.Suspense> },
                { path: 'bonus-transaction-type', element: <React.Suspense fallback={<>...</>}><BonusTransactionTypePage /></React.Suspense> },
                { path: 'bonus-rating-month', element: <React.Suspense fallback={<>...</>}><BonusRatingMonthPage /></React.Suspense> },
                { path: 'app-version', element: <React.Suspense fallback={<>...</>}><MobileAppVersionPage /></React.Suspense> },
                { path: 'call-type', element: <React.Suspense fallback={<>...</>}><CallTypePage /></React.Suspense> },
                { path: 'call-state', element: <React.Suspense fallback={<>...</>}><CallStatePage /></React.Suspense> }
            ] }
        ]
    }
];